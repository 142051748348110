// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDJ-YEMXkorkLy8rWkxBPJnJ-2BUQH-WkQ",
  authDomain: "mahadi-net.firebaseapp.com",
  projectId: "mahadi-net",
  storageBucket: "mahadi-net.appspot.com",
  messagingSenderId: "125542085750",
  appId: "1:125542085750:web:95acb565b54b94eed5db57",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export default app;
