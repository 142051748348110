import React, { useState } from "react";

import Banner from "../Banner/Banner";
import Portfolio from "../Projects/Portfolio";
import Blog from "../Blogs/Blog";

import Contact from "../Contact/Contact";
import { useRef } from "react";
import RefDataContext from "../../contexts/RefDataContext";
const Home = () => {
  const project = useRef(null);
  const blogs = useRef(null);
  const contact = useRef(null);
  const scrollToComponent = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div>
      <RefDataContext.Provider
        value={{
          scrollToComponent,
          project,
          blogs,
        }}
      >
        <div>
          <Banner />
        </div>
        <div ref={project}>
          <Portfolio />
        </div>
        <div ref={blogs}>
          <Blog />
        </div>
        <div ref={contact}>
          <Contact />
        </div>
      </RefDataContext.Provider>
    </div>
  );
};

export default Home;
