import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import { HiMenu } from "react-icons/hi";
import { MdClose } from "react-icons/md";
import { AiFillProject } from "react-icons/ai";
import { AuthContext } from "../../contexts/AuthProvider";
import { Logo } from "../../Form/Logo";
import RefDataContext from "../../contexts/RefDataContext";

const Navbar = () => {
  const [changeHeader, setChangeHeader] = useState(false);
  const [open, setOpen] = useState(false);
  const { user, logOut } = useContext(AuthContext);

  //header change function
  const onChangeHeader = () => {
    if (window.scrollY >= 50) {
      setChangeHeader(true);
    } else {
      setChangeHeader(false);
    }
  };

  //change header by scrolling
  window.addEventListener("scroll", onChangeHeader);

  return (
    <div>
      <header className="md:py-3.5 py-3.5 lg:py-0 mx-2">
        <div class="container mx-auto">
          <div class="relative -mx-4 flex items-center justify-between">
            <Logo />
            <div
              onClick={() => setOpen(!open)}
              class="absolute right-4 lg:hidden md:hidden flex
                  items-center justify-center "
            >
              {open ? (
                <MdClose className="text-3xl" />
              ) : (
                <HiMenu className="text-3xl" />
              )}
            </div>
            <div class="flex w-full items-center justify-between px-4 ">
              <div>
                <nav class="absolute right-4 top-full w-full max-w-[250px] rounded-lg py-5 px-6 shadow lg:static lg:w-full lg:max-w-full lg:shadow-none hidden lg:block">
                  <ul class="block lg:flex py-2">
                    {/* <NavLink to="/">
                      <h1 class="text-dark hover:text-primary flex py-2 text-base font-medium lg:ml-12 lg:inline-flex ">
                        Home
                      </h1>
                    </NavLink> */}

                    {/* <NavLink to="/projects">
                      <h1 class="text-dark hover:text-primary flex py-2 text-base font-medium lg:ml-12 lg:inline-flex">
                        Projects
                      </h1>
                    </NavLink>
                    <NavLink to="/blogs">
                      <h1 class="text-dark hover:text-primary flex py-2 text-base font-medium lg:ml-12 lg:inline-flex">
                        Blogs
                      </h1>
                    </NavLink>
                    <NavLink to="/about">
                      <h1 class="text-dark hover:text-primary flex py-2 text-base font-medium lg:ml-12 lg:inline-flex">
                        About
                      </h1>
                    </NavLink> */}
                  </ul>
                </nav>

                {/* mobile responsive */}
                <nav
                  class={
                    open
                      ? "absolute  left-2 w-full   bg-white py-5 px-6 shadow  lg:w-full lg:max-w-full lg:shadow-none mt-6 bg-lime-300"
                      : "hidden"
                  }
                >
                  <ul class="block lg:flex">
                    {/* <NavLink
                      to="/dashboard"
                      className="px-2 flex items-center justify-start gap-1 text-dark hover:text-primary flex py-2 text-base font-medium lg:ml-12 lg:inline-flex "
                    >
                      <AiFillProject className="text-xl" />
                      <h1 class="">Dashboard</h1>
                    </NavLink> */}
                    {/* <NavLink
                      to="/"
                      className="px-2 flex items-center justify-start gap-1 text-dark hover:text-primary flex py-2 text-base font-medium lg:ml-12 lg:inline-flex "
                    >
                      <h1 class="">Home</h1>
                    </NavLink> */}
                    {/* <NavLink
                      to="/blogs"
                      className="px-2 flex items-center justify-start gap-1 text-dark hover:text-primary flex py-2 text-base font-medium lg:ml-12 lg:inline-flex "
                    >
                      <h1 class="">Blogs</h1>
                    </NavLink>
                    <NavLink
                      to="/projects"
                      className="px-2 flex items-center justify-start gap-1 text-dark hover:text-primary flex py-2 text-base font-medium lg:ml-12 lg:inline-flex "
                    >
                      <h1 class="">Projects</h1>
                    </NavLink>
                    <NavLink
                      to="/about"
                      className="px-2 flex items-center justify-start gap-1 text-dark hover:text-primary flex py-2 text-base font-medium lg:ml-12 lg:inline-flex "
                    >
                      <h1 class="">About</h1>
                    </NavLink> */}
                  </ul>
                </nav>
              </div>
              <div class="hidden justify-end pr-16 sm:flex lg:pr-0">
                {/* {user?.email ? (
                  <NavLink
                    to="/dashboard"
                    className="px-2 mr-4 flex items-center justify-start gap-1 text-dark hover:text-primary flex py-2 text-base font-medium lg:ml-12 lg:inline-flex "
                  >
                    <AiFillProject className="text-xl" />
                    <h1 class="">Dashboard</h1>
                  </NavLink>
                ) : (
                  <h1 class="text-dark hover:text-primary py-3 px-7 text-base font-medium">
                    Login
                  </h1>
                )}
                {user?.email ? (
                  <h1
                    onClick={() => logOut()}
                    class="bg-lime-500 rounded-lg py-3 px-7 text-base font-medium text-white hover:bg-opacity-90"
                  >
                    Sign Out
                  </h1>
                ) : (
                  <NavLink
                    to="/login"
                    class="bg-lime-500 rounded-lg py-3 px-7 text-base font-medium text-white hover:bg-opacity-90"
                  >
                    Sign Up
                  </NavLink>
                )} */}

                <a href="https://wa.me/8801785083768" class="bg-lime-500 rounded-lg py-3 px-7 text-base font-medium text-white hover:bg-opacity-90 cursor-pointer">
                  Hire Me
                </a>
                
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Navbar;
