import { useEffect, useState } from "react";
const useProjects = (url) => {
  const [projects, setProjects] = useState(null);
  const [loading, setloading] = useState(true);
  const [error, seterror] = useState("");
  useEffect(() => {
    const url = "https://mahadi-net.malihatabassum.com/projects";
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        seterror(data.error);
        setProjects(data);
        setloading(false);
      });
  }, [url]);
  return { projects, loading, error, setProjects };
};
export default useProjects;
