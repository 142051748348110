// import React, { useContext } from "react";
// import { useNavigate, useRouteError } from "react-router-dom";
// import { AuthContext } from "../contexts/AuthProvider";

// const DisplayError = () => {
//   const { logOut } = useContext(AuthContext);
//   const error = useRouteError();
//   const navigate = useNavigate();

//   const handleLogOut = () => {
//     logOut()
//       .then(() => {
//         navigate("/login");
//       })
//       .catch((err) => console.log(err));
//   };

//   return (
//     <div>
//       <p className="text-red-500">Something went wrong!!!</p>
//       <p className="text-red-400">{error.statusText || error.message}</p>
//       <h4 className="text-3xl">
//         {" "}
//         Please <button onClick={handleLogOut}>Sign out</button> and log back in
//       </h4>
//     </div>
//   );
// };

// export default DisplayError;

import React from "react";
import { useNavigate } from "react-router-dom";

const DisplayError = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div class="flex items-center justify-center py-12">
        <div class="bg-white dark:bg-gray-800 border dark:border-gray-900 rounded-md flex items-center justify-center mx-4 md:w-2/3">
          <div class="flex flex-col items-center py-16">
            <img
              class="px-4 hidden md:block"
              src="https://i.ibb.co/9Vs73RF/undraw-page-not-found-su7k-1-3.png"
            />
            <img
              class="md:hidden"
              src="https://i.ibb.co/RgYQvV7/undraw-page-not-found-su7k-1.png"
            />
            <h1 class="px-4 pt-8 pb-4 text-center dark:text-white text-5xl font-bold leading-10 text-gray-800">
              OOPS!
            </h1>
            <p class="px-4 pb-10 text-base leading-none dark:text-gray-200 text-center text-gray-600">
              No signal here! we cannot find the page you are looking for
            </p>
            <button
              onClick={() => navigate(-1)}
              class="mx-4 h-10 w-44  rounded-md text-white text-base bg-indigo-700 hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-opacity-50 focus:ring-indigo-800"
            >
              Go Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisplayError;
