import { RouterProvider } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import router from "./routes/Routes";
import Queue from "./components/Queue";
import { FloatingWhatsApp } from "react-floating-whatsapp";
function App() {
  return (
    <div className="max-w-[1440px] mx-auto">
      <FloatingWhatsApp
        phoneNumber="+8801785083768"
        accountName="Mahadi Hasan"
        avatar="https://res.cloudinary.com/duyu26dwt/image/upload/v1698685253/365160369_2208715956006390_8282146332150632758_n_z53svb.jpg"
        statusMessage="Typically replies within 15 minuts"
        buttonClassName=""
      />
      <RouterProvider router={router}></RouterProvider>
      <Toaster></Toaster>
    </div>
  );
}

export default App;
