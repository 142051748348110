import { createBrowserRouter } from "react-router-dom";
import Login from "../Authentication/Login";
import SignUp from "../Authentication/SignUp";
import DashboardLayout from "../Layout/DashbordLayout";
import Main from "../Layout/Main";
import DisplayError from "../shared/DisplayError";
import AdminRoute from "./AdminRoute";
import PrivateRoute from "./PrivateRoute";
import Home from "../components/HomeScreen/Home";
import AddProjects from "../components/Projects/AddProjects";
import ManageBlogs from "../components/Blogs/ManageBlogs";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main></Main>,
    errorElement: <DisplayError></DisplayError>,
    children: [
      {
        path: "/",
        element: <Home></Home>,
      },
      {
        path: "/login",
        element: <Login></Login>,
      },
      {
        path: "/signup",
        element: <SignUp></SignUp>,
      },
    ],
  },
  {
    path: "/dashboard",
    element: (
      <PrivateRoute>
        <DashboardLayout></DashboardLayout>
      </PrivateRoute>
    ),
    errorElement: <DisplayError></DisplayError>,
    children: [
      {
        path: "/dashboard",
        element: (
          <div className="text-center">
            <div className="bg-gray-200 min-h-screen">
              <div className="p-6">
                <h1 className="text-2xl font-bold mb-6">Dashboard</h1>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="bg-white rounded shadow p-6">
                    <h2 className="text-lg font-bold mb-4">Users</h2>
                    <p className="text-gray-500">
                      Number of registered users: 100
                    </p>
                  </div>
                  <div className="bg-white rounded shadow p-6">
                    <h2 className="text-lg font-bold mb-4">Sales</h2>
                    <p className="text-gray-500">Total sales: $10,000</p>
                  </div>
                  <div className="bg-white rounded shadow p-6">
                    <h2 className="text-lg font-bold mb-4">Traffic</h2>
                    <p className="text-gray-500">Visitors today: 500</p>
                  </div>
                  <div className="bg-white rounded shadow p-6">
                    <h2 className="text-lg font-bold mb-4">Products</h2>
                    <p className="text-gray-500">Number of products: 50</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ),
      },

      {
        path: "/dashboard/add-projects",
        element: (
          <PrivateRoute>
            <AddProjects />
          </PrivateRoute>
        ),
      },
      {
        path: "/dashboard/add-blogs",
        element: (
          <PrivateRoute>
            <ManageBlogs />
          </PrivateRoute>
        ),
      },

      //   {
      //     path: "/dashboard/payment/:id",
      //     element: <Payment></Payment>,
      //     loader: ({ params }) =>
      //       fetch(
      //         `https://doctors-portal-server-rust.vercel.app/bookings/${params.id}`
      //       ),
      //   },
    ],
  },
]);

export default router;
