import React, { useState } from "react";
import Typewriter from "typewriter-effect";
import banner_lg from "../../asset/img/banner-lg-pro.png";
import banner_sm from "../../asset/img/banner-sm.jpg";
import banner_md from "../../asset/img/banner-md.jpg";

const Banner = () => {
  return (
    <section className=" reletive">
      <div className="">
        <div className="flex justify-center items-center md:justify-start  ">
          <img
            className="hidden lg:block  w-full static"
            src={banner_lg}
            alt="randeer"
          />
          <div class="bg-gradient-to-r from-indigo-500 ...  "></div>
          <img
            className="md:block lg:hidden hidden  w-full static"
            src={banner_md}
            alt="randeer"
          />

          <img
            className="md:hidden w-full static "
            src={banner_sm}
            alt="randeer"
          />
          <div className="flex absolute justify-start flex-col md:flex-row items-center overflow-hidden ">
            <div className="py-32 sm:py-20  md:hidden" />
            <div className="lg:flex flex-col lg:justify-start lg:items-start  py-10 md:justify-start items-center lg:text-left md:text-left text-center lg:mx-0 md:mx-4 px-2">
              <h1 className="lg:text-6xl text-3xl textColor font-bold leading-2 uppercase">
                It's Mahadi Hasan
              </h1>
              <span className="lg:text-6xl text-3xl text-gray-500 font-bold leading-2 uppercase">
                <Typewriter
                  options={{
                    strings: [
                      "I Love Coding",
                      "I develop web app",
                      "Full stack web developer",
                    ],
                    autoStart: true,
                    loop: true,
                  }}
                />
              </span>
              <p className="lg:w-96 sm:w-64 lg:w-2/3 mt-4  leading-6 xl:leading-5 text-gray-800  mb-4 lg:text-start  text-md flex  ">
                I'm a passionate web designer & front‑end developer focused on
                crafting clean & user‑friendly experiences, I am passionate
                about building excellent software that improves the lives of
                those around me.
              </p>
              {/* <Modal open={open} onClose={onCloseModal} center>
                <About></About>
              </Modal> */}

              <button class="btn">
                <span class="circle">
                  <span class="icon arrow"></span>
                </span>
                <a href="https://aboutmahadi.my.canva.site/"  >
                <span class="button-text">About me</span>
                </a>
                
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
