import React from "react";
import { Link } from "react-router-dom";

export const Logo = () => {
  return (
    <Link to="/" class="max-w-full px-4">
      <h1 className=" leading-4 font-extrabold uppercase text-3xl lg:text-5xl text-gray-700 reletive ">
        Mahadi<span className="textColor">.</span>
      </h1>
    </Link>
  );
};
