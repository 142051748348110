import React, { useState } from "react";
import useProjects from "../hook/useProjects";
import { Link } from "react-router-dom";
const Portfolio = () => {
  const [action, setAction] = useState(false);
  const [id, setID] = useState(0);
  const { projects, setProjects } = useProjects();
  const [filterTab, setFilterTab] = useState("All");
  const categorys = [
    "All",
    "E-commerce",
    "Blogs",
    "Productivity",
    "Health",
    "Travel",
    "Property",
  ];

  return (
    <section className="scroll-smooth">
      <div className="py-12 px-4 md:px-6 xl:px-0 md:container xl:mx-auto overflow-x-hidden ">
        <div className="flex lg:justify-center lg:items-center items-center justify-center pb-8 lg:pb-0 md:pb-0">
          <h1 className="mx-4 leading-4 font-extrabold uppercase lg:text-9xl text-gray-700/5 absolute object-fit">
            Works
          </h1>
          <h1 className="leading-4 font-extrabold uppercase text-3xl lg:text-5xl text-gray-700 reletive md:mb-8 lg:mb-0 ">
            Portfo<span className="textColor">lio</span>
          </h1>
        </div>
        {/* <div class="-mx-4 flex flex-wrap">
          <div class="w-full px-4">
            <div class="mx-auto mb-[60px] max-w-[510px] text-center pt-8">
              <h2 class="text-dark mb-4 text-3xl font-bold sm:text-4xl md:text-[40px]">
                Our Recent Projects
              </h2>
              <p class="text-body-color text-base">
                There are many variations of passages of Lorem Ipsum available
                but the majority have suffered alteration in some form.
              </p>
            </div>
          </div>
        </div> */}

        <div class="w-full px-4 lg:mt-16">
          <ul class="mb-12 flex flex-wrap justify-center space-x-1">
            {categorys?.map((data, index) => (
              <li class="mb-1" onClick={() => setID(index + 0)}>
                <button
                  key={index}
                  onClick={() => setFilterTab(data)}
                  class={
                    id === index + 0
                      ? "inline-block rounded-lg py-2 px-5 text-center text-base font-semibold transition md:py-3 lg:px-8 w-full focus:bg-lime-500 bg-lime-500 text-white"
                      : "inline-block rounded-lg py-2 px-5 text-center text-base font-semibold transition md:py-3 lg:px-8 w-full focus:bg-lime-500"
                  }
                >
                  {data}
                </button>
              </li>
            ))}
          </ul>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 pt-5 justify-center items-center my-10">
          {projects
            ?.filter(
              (e) => e.category === filterTab || filterTab === categorys[0]
            )
            .map((data) => (
              <div class="w-full px-4 ">
                <div class="mb-10 overflow-hidden rounded-lg bg-white  ">
                  <img
                    src={data?.images[0]}
                    alt={data?.images[0]}
                    class="w-full h-56 object-cover"
                  />
                  <h1 className="text-semibold ml-4 text-sm text-lime-600">
                    {data.category}
                  </h1>
                  <div class="p-4 text-center sm:p-9 md:p-3 xl:p-4">
                    <h3>
                      <h1 class="text-dark hover:text-primary mb-4 block text-xl font-semibold sm:text-[22px] md:text-xl lg:text-[22px] xl:text-xl 2xl:text-[22px]">
                        {data.title}
                      </h1>
                    </h3>
                    <p class="text-body-color mb-7 text-base leading-relaxed">
                      {data.description.slice(0, 95)}...
                    </p>
                    <Link class="text-body-color hover:border-primary hover:bg-lime-500 inline-block rounded-md border border-[#E5E7EB] py-2 px-7 text-base font-medium transition hover:text-white mr-2">
                      View Details
                    </Link>
                    <a
                      href={data.preview}
                      class="text-body-color hover:border-primary  text-gray-100 bg-lime-500  inline-block rounded-md border border-[#E5E7EB] py-2 px-7 text-base font-medium transition hover:text-white"
                    >
                      Live Site
                    </a>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
