import React, { useState } from "react";
import useBlogs from "../hook/useBlogs";

const Blog = () => {
  const { blogs } = useBlogs();

  return (
    <section class="py-12">
      <div class="container mx-auto">
        <div class="-mx-4 flex flex-wrap justify-center">
          <div class="w-full px-4 pb-4">
            <div className="flex lg:justify-center lg:items-center items-center justify-center pb-8 lg:pb-0 md:pb-0">
              <h1 className="mx-4 leading-4 font-extrabold uppercase lg:text-9xl text-gray-700/5 absolute object-fit">
                Blogs
              </h1>
              <h1 className="leading-4 font-extrabold uppercase text-3xl lg:text-5xl text-gray-700 reletive md:mb-8 lg:mb-0 ">
                <span className="textColor">Blogs</span>
              </h1>
            </div>
          </div>
        </div>
        <div class="-mx-4 flex flex-wrap mt-12">
          {blogs?.map((data) => (
            <div class="w-full px-4 md:w-1/2 lg:w-1/3">
              <div class="mx-auto mb-10 max-w-[370px]">
                <div class="mb-8 overflow-hidden rounded h">
                  <img
                    src={data.images[0]}
                    alt={data.images[0]}
                    class="w-full lg:h-56 object-cover"
                  />
                </div>
                <div>
                  <span class="bg-lime-500 mb-5 inline-block rounded py-1 px-4 text-center text-xs font-semibold leading-loose text-white">
                    {data.date?.slice(4, 16)}
                  </span>
                  <h3>
                    <a
                      href="javascript:void(0)"
                      class="text-dark hover:text-primary mb-4 inline-block text-xl font-semibold sm:text-2xl lg:text-xl xl:text-2xl"
                    >
                      {data.title}
                    </a>
                  </h3>
                  <p class="text-body-color text-base">
                    {data.description?.slice(0, 100)}...
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Blog;
