import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import {
  AiFillDelete,
  AiFillEdit,
  AiOutlineEdit,
  AiOutlineUpload,
} from "react-icons/ai";
import useProjects from "../hook/useProjects";
// import useAuth from "../hooks/useAuth";
import TextareaAutosize from "react-textarea-autosize";

const AddProjects = () => {
  const { projects, setProjects } = useProjects();
  const [action, setAction] = useState(false);
  const [id, setID] = useState();
  const [title, setTitle] = useState("");
  const [tagline, setTagline] = useState("");
  const [category, setCategory] = useState("E-commerce");
  const [preview, setPreview] = useState("");
  const [github, setGithub] = useState();
  const [description, setDescription] = useState("");
  const [images, setImage] = useState("");
  const [defaultTitle, setDefaultTitle] = useState("");
  const [defaulDes, setDefaultDes] = useState("");
  const [loading, setLoading] = useState(false);
  //   const email = user.email;
  //   const photoURL = user.photoURL;
  //   const name = user.displayName;
  //   const uid = user.uid;
  const date = new Date().toString();

  const handleAddProject = () => {
    const newNote = {
      title,
      description,
      date,
      preview,
      github,
      category,
      images,
      tagline,
    };
    setProjects([newNote, ...projects]);
    setTitle("");
    setDescription("");
    setGithub("");
    setPreview("");
    fetch("https://mahadi-net.malihatabassum.com/projects", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(newNote),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.insertedId) {
          toast.success("Successfully added the Projects.");
        }
      });
  };

  function handleOpenWidget() {
    var myWidget = window.cloudinary.createUploadWidget(
      {
        cloudName: "dmh446knl",
        uploadPreset: "abpwtnmb",
      },
      (error, result) => {
        if (result.uw_event === true) {
          setLoading(false);
        }
        console.log(result);
        if (result.event === "queues-end") {
          const files = result.info.files.map(
            (element) => element.uploadInfo.url
          );
          setImage(files);
        }
      }
    );
    //open widget
    myWidget.open();
  }

  const handleUpdate = (data) => {
    setDefaultTitle(data.title);
    setDefaultDes(data.description);
    setID(data._id);
    setAction(true);
  };
  const note_title = title ? title : defaultTitle;
  const note_des = description ? description : defaulDes;
  const handleUpdateAddProjects = () => {
    const update_note = {
      note_title,
      note_des,
    };
    const url = `https://mahadi-net.malihatabassum.com/projects/${id}`;
    fetch(url, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(update_note),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.modifiedCount > 0) {
          toast.success("Update Successful");
          setAction(false);
        }
      });
  };

  const handleDeleteProjects = (id) => {
    const proceed = window.confirm("Are you sure, you want to delete?");
    if (proceed) {
      const url = `https://mahadi-net.malihatabassum.com/projects/${id}`;
      fetch(url, {
        method: "DELETE",
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.deletedCount > 0) {
            toast.success("Deleted successfully");
            const remainingAddProjects = projects.filter(
              (user) => user._id !== id
            );
            setProjects(remainingAddProjects);
          }
        });
    }
  };
  console.log(category);
  return (
    <div>
      <div className="mx-auto container mt-12">
        <Toaster />
        <div className="flex flex-col justify-start lg:flex-row md:flex-row gap-4">
          <div className="lg:w-[500px] w-full flex flex-col gap-2 w-1/2 ">
            <select
              id="cars"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              className="py-2.5 rounded bg-gray-400 text-gray-100"
            >
              <option value="E-commerce">E-commerce</option>
              <option value="Health">Health</option>
              <option value="Education">Education</option>
              <option value="Blogs">Blogs</option>
              <option value="Property">Property</option>
              <option value="Travel">Travel</option>
              <option value="Productivity">Productivity </option>
            </select>
            <input
              type="text"
              class="bg-gray-50 py-2.5 px-2 rounded focus:border-cyan-500 outline-none focus:ring-cyan-500"
              placeholder="Project title"
              defaultValue={defaultTitle}
              onChange={(e) => setTitle(e.target.value)}
            />

            <input
              type="text"
              class="bg-gray-50 py-2.5 px-2 rounded focus:border-cyan-500 outline-none focus:ring-cyan-500"
              placeholder="Tagline"
              defaultValue={tagline}
              onChange={(e) => setTagline(e.target.value)}
            />
            <input
              type="text"
              class="bg-gray-50 py-2.5 px-2 rounded focus:border-cyan-500 outline-none focus:ring-cyan-500"
              placeholder="Project Preview"
              defaultValue={defaultTitle}
              onChange={(e) => setPreview(e.target.value)}
            />
            <input
              type="text"
              class="bg-gray-50 py-2.5 px-2 rounded focus:border-cyan-500 outline-none focus:ring-cyan-500"
              placeholder="Github Link"
              defaultValue={defaultTitle}
              onChange={(e) => setGithub(e.target.value)}
            />

            <div class="w-full mb-4 border border-gray-200 rounded-lg bg-gray-50  mt-2">
              <div class="bg-white rounded-t-lg ">
                <TextareaAutosize
                  minRows={10}
                  maxRows={40}
                  class="bg-gray-50 w-full py-2.5 px-2 rounded focus:border-cyan-500 outline-none focus:ring-cyan-500"
                  placeholder="Project description..."
                  required
                  onChange={(e) => setDescription(e.target.value)}
                  defaultValue={defaulDes}
                />
              </div>

              <div class="flex items-center justify-between px-3 py-2 border-t border-gray-200 dark:border-gray-600">
                {action ? (
                  <button
                    type="submit"
                    onClick={() => handleUpdateAddProjects()}
                    class=" items-center py-2.5 px-4 text-xs font-medium text-center text-white bg-cyan-700 rounded-lg focus:ring-4 focus:ring-cyan-200 dark:focus:ring-cyan-900 hover:bg-cyan-800"
                  >
                    Update Project
                  </button>
                ) : (
                  <button
                    type="submit"
                    onClick={handleAddProject}
                    class=" items-center py-2.5 px-4 text-xs font-medium text-center text-white bg-lime-500 rounded-lg focus:ring-4 focus:ring-cyan-200 dark:focus:ring-cyan-900 hover:bg-cyan-800 w-fit"
                  >
                    Add project
                  </button>
                )}

                <div
                  class="flex pl-0 space-x-1 sm:pl-2 cursor-pointer"
                  onClick={handleOpenWidget}
                >
                  <AiOutlineUpload className="text-3xl text-cyan-500" />
                </div>
              </div>
            </div>
          </div>

          <div className="w-1/2">
            <div class="relative overflow-x-auto">
              <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" class="px-6 py-3">
                      Name
                    </th>
                    <th scope="col" class="px-6 py-3">
                      Preview
                    </th>
                    <th scope="col" class="px-6 py-3">
                      Category
                    </th>
                    <th scope="col" class="px-6 py-3">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {projects?.map((data) => (
                    <tr class="bg-white border-b  text-gray-700">
                      <th
                        scope="row"
                        class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                      >
                        {data.title}
                      </th>
                      <td class="px-6 py-4">{data.preview}</td>
                      <td class="px-6 py-4">{data.category}</td>
                      <td class="px-6 py-4 flex">
                        <div
                          className="cursor-pointer"
                          onClick={() => handleDeleteProjects(data._id)}
                        >
                          <AiFillDelete className="text-red-500 text-2xl "></AiFillDelete>
                        </div>

                        <div
                          className="cursor-pointer"
                          onClick={() => handleUpdate(data._id)}
                        >
                          <AiOutlineEdit className=" text-2xl ">
                            {" "}
                          </AiOutlineEdit>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProjects;
